.what_is_conatiner {
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.what_is_header {
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  background: linear-gradient(to right, #993d9a, #db314f, #fdab43);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}

.what_is_desc {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 30px;
}

.what_is_items {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.what_is_item {
  background: linear-gradient(to right, #993d9a, #db314f, #fdab43);
  height: 100%;
  border-radius: 19px;
  padding: 1px;
  display: flex;
}

.what_is_item_text {
  background-color: #1b181d;
  width: 100%;
  height: 100%;
  border-radius: 19px;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffd19d;
  font-size: 20px;
  font-weight: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.what_is_heade_small_screen {
  display: none;
}

.what_is_desc_small_screen {
  display: none;
}

.what_is_items_small_screen {
  display: none;
}

@media (max-width: 800px) {
  .what_is_conatiner {
    width: 95%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .what_is_header {
    display: none;
  }

  .what_is_heade_small_screen {
    display: block;
    font-size: 30px;
    font-weight: 700;
    background: linear-gradient(to right, #993d9a, #db314f, #fdab43);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 20px;
  }

  .what_is_desc {
    display: none;
  }

  .what_is_desc_small_screen {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .what_is_items {
    display: none;
  }

  .what_is_items_small_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .what_is_items_small_screen_1,
  .what_is_items_small_screen_2 {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .what_is_items_small_screen_1 {
    justify-content: space-around;
  }

  .what_is_items_small_screen_2 {
    justify-content: space-between;
  }

  .what_is_item {
    border-radius: 16px;
  }

  .what_is_item_text {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    border-radius: 16px;
  }
}
