.add_container {
  width: 80%;
  height: 308px;
  border-radius: 61px;
  background-color: #1b181d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add_text_conatainer {
  width: 55%;
  height: 100%;
  font-size: 32px;
  font-weight: 500px;
  line-height: 40px;
  color: #ffd19d;
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.add_img_container {
  width: 45%;
  margin-right: 5%;
}

.people_img {
  width: 100%;
  height: 430px;
}

.add_text_conatainer_small_screen {
  display: none;
}

@media (max-width: 800px) {
  .add_container {
    width: 95%;
    height: auto;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .add_text_conatainer {
    display: none;
  }

  .add_img_container {
    width: 45%;
    margin-right: 0;
  }

  .people_img {
    width: 100%;
    height: 240px;
  }

  .add_text_conatainer_small_screen {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 500px;
    line-height: 25px;
    color: #ffd19d;
    padding-left: 20px;
    padding-right: 20px;
  }
}
