.top_main_container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.top_min_bg_img {
  width: 100%;
  height: 100%;
}

.top_main_logo {
  position: absolute;
  width: 400px;
  height: 380px;
  top: 10px;
  object-fit: contain;
  z-index: 10;
}

.top_heading_container {
  font-size: 56px;
  font-weight: 600;
  line-height: 60px;
  background: linear-gradient(to right, #993d9a, #db314f, #fdab43);
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  position: absolute;
  bottom: 70px;
}

@media (max-width: 800px) {
  .top_main_container {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .top_main_logo {
    width: 250px;
    height: 130px;
    top: 70px;
  }

  .top_heading_container {
    font-size: 40px;
    line-height: 45px;
    bottom: 50px;
    width: 70%;
  }
}
