.homeScreen_main_container {
  position: relative;
}

.left_bg_container {
  width: 100%;
  height: 2000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 60vh;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeScreen_add_container {
  width: 100%;
  position: absolute;
  top: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.right_bg_container {
  width: 100%;
  height: 2000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 1400px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeScreen_people_container {
  width: 100%;
  position: absolute;
  top: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.people_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
}

.people_header_text {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(to right, #993d9a, #db314f, #fdab43);
  -webkit-background-clip: text;
  color: transparent;
}

.people_image_container {
  width: 100%;
  height: 520px;
  margin-top: 30px;
}

.speakers_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home_screen_footer_container {
  width: 70%;
  height: 160px;
  background: linear-gradient(to bottom right, #2c2c2c 10%, #080808 60%);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footer_header {
  font-size: 40px;
  font-weight: 700;
}

.contact_items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact_item_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-right: 30px;
}

.contact_img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.conatct_text {
  font-size: 22px;
  font-weight: 500px;
}

.speakers_img_small {
  display: none;
}

@media (max-width: 800px) {
  .left_bg_container {
    height: 2000px;
    top: 30vh;
  }

  .right_bg_container {
    top: 2050px;
  }

  .people_container {
    width: 95%;
  }

  .people_image_container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .speakers_img {
    display: none;
  }

  .speakers_img_small {
    display: block;
    width: 90%;
    height: 100%;
    object-fit: contain;
  }

  .home_screen_footer_container {
    width: 95%;
    height: auto;
    gap: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 22px;
    background: linear-gradient(to bottom right, #2c2c2c 40%, #080808 60%);
  }

  .footer_header {
    font-size: 24px;
  }

  .contact_items_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .contact_item_container {
    gap: 10px;
    margin-right: 0px;
  }

  .contact_img {
    width: 20px;
    height: 20px;
  }

  .conatct_text {
    font-size: 12px;
  }
}
