.images_container {
  width: 87%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.images_heading_container {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai_summit_img {
  width: 100%;
  height: 135px;
}

.image_container {
  width: 100%;
  height: 425px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.image_container_first {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image_container_first_1 {
  width: 100%;
  height: 49.5%;
}

.image_container_first_2 {
  width: 100%;
  height: 49.5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.image_container_first_2_1 {
  width: 40%;
  height: 100%;
}

.image_container_first_2_2 {
  width: 59%;
  height: 100%;
}

.image_container_second {
  width: 40%;
  height: 100%;
}

.image_container_third {
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image_container_third_1 {
  width: 100%;
  height: 60%;
}

.image_container_third_2 {
  width: 100%;
  height: 39%;
}

.image_item_img {
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .images_container {
    width: 95%;
  }

  .image_container {
    height: auto;
    border-radius: 16px;
    flex-direction: column;
    gap: 10px;
  }

  .image_container_first {
    width: 100%;
    height: 100%;
  }

  .image_container_first_1 {
    height: 180px;
    margin-bottom: 10px;
  }

  .image_container_first_2_1 {
    height: 150px;
  }

  .image_container_first_2_2 {
    height: 150px;
  }

  .image_container_second {
    width: 100%;
    height: 260px;
  }

  .image_container_third {
    width: 100%;
    height: 100%;
  }

  .image_container_third_1 {
    height: 240px;
    margin-bottom: 10px;
  }

  .image_container_third_2 {
    height: 170px;
  }

  .images_heading_container {
    width: 80%;
  }
  
  .ai_summit_img {
    height: 100px;
  }
}
